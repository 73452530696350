import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Tooltip, Select, DatePicker, Table, Row, Col, Button, Form, message } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { toggleLoadingAction, loginAction, checkTokenAction } from '../../actions/global';
import { API_DOMAIN, DATASET_PATH, X_API_KEY } from '../../config/global';
import './index.css';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Column } = Table;

const SubmitFilterForm = Form.create()(
  (props) => {
    const { onSubmit, form } = props;
    const { getFieldDecorator } = form;
    const rangeConfig = {
      rules: [{ type: 'array' }],
    };
    return (
      <Form className="ant-advanced-search-form">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Date" style={{ marginBottom: 0 }}>
              {getFieldDecorator('rangeTimePicker', rangeConfig)(
                <RangePicker format="YYYY-MM-DD" style={{ width: '100%' }} />,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status" style={{ marginBottom: 0 }}>
              {getFieldDecorator('status', {
                initialValue: 'all',
              })(
                <Select>
                  <Option value="all">All</Option>
                  <Option value="initiated">Initiated</Option>
                  <Option value="in_progress">In Progress</Option>
                  <Option value="timedout">Time Out</Option>
                  <Option value="failed">Failed</Option>
                  <Option value="completed">Completed</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" onClick={onSubmit} style={{ width: '100%' }} icon="search">
                Search Request
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  });
const SubmitRequestForm = Form.create()(
  (props) => {
    const { onSubmit, form, isButtonDisabled } = props;
    const { getFieldDecorator } = form;
    return (
      <Form layout="inline">
        <Form.Item label="Extraction Mode" style={{ marginBottom: 0 }}>
              {getFieldDecorator('extractionMode', {
                initialValue: 'incremental',
              })(
                <Select>
                  <Option value="incremental">Incremental</Option>
                  <Option value="full">Full</Option>
                </Select>
              )}
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button disabled={isButtonDisabled} type="danger" style={{ width: '100%' }} onClick={onSubmit}>
            Submit Request
          </Button>
        </Form.Item>
      </Form>
    )
  });

class SecurityLog extends React.Component {
  state = {
    dataListOfRequest: [],
    dataAvailableFiles: [],
    loadingListOfRequest: false,
    loadingDataAvailableFiles: false,
    isButtonDisabled: false,
  }


  componentDidMount() {
    localStorage.setItem('selectedTab', 'SL');
    if (this.props.icao != null) {
      this.getData();
      this.getAvailableFileData();
    }
  }

  getAvailableFileData() {
    this.setState({ loadingDataAvailableFiles: true }, () => {
      return this.props.checkTokenAction()
        .then(() => {
          let icao = [];
          for (let item of this.props.permissions) {
            for (let permission of item.permissions) {
              if (permission.code === "LINKSSL" || permission.code === "DOWNLOADSL") {
                if (item.company.icao !== '') {
                  icao.push(item.company.icao);
                  break;
                }
              }
            }
          }
          return axios({
            method: 'GET',
            url: `${API_DOMAIN}/mde/od/request?request_type=SL&icao=${this.props.icao}`,
            headers: { 'x-api-key': `${X_API_KEY}` }
          });
        }).then(result => {
          let data = result.data;
          let dataAvailableFiles = [];
          for (let item of data.values) {
            item.request_time = moment.utc(item.request_time).local().format('YYYY-MM-DDTHH:mm:ss')
            if (item.request_status !== 'deleted') {
              if (item.request_status === 'completed' || item.request_status === 'archived') {
                dataAvailableFiles.push(item);
              }
            }
          }
          let dataAvailableFilesGroupBy = [];
          for (let i of dataAvailableFiles) {
            let match = false;
            for (let j of dataAvailableFilesGroupBy) {
              if (j.name != null && j.name === i.datasetRid) {
                j.items.push(i);
                if (Date.parse(i.request_time) > j.time) {
                  j.time = Date.parse(i.request_time);
                  j.dataset_name = i.dataset_name;
                }
                match = true;
                break;
              }
            }
            if (match === false) {
              dataAvailableFilesGroupBy.push({
                name: i.datasetRid,
                dataset_name: i.dataset_name,
                items: [i],
                time: Date.parse(i.request_time),
              })
            }
          }
          this.setState({
            dataAvailableFiles: dataAvailableFilesGroupBy.sort(function (a, b) { return moment(b.time) - moment(a.time) }),
          }, () => {
            this.setState({ loadingDataAvailableFiles: false });
          });
        }).catch((error) => {
          this.setState({ loadingDataAvailableFiles: false });
          message.error(JSON.stringify(error));
        });
    });
  }

  getData(params = null) {
    this.setState({ loadingListOfRequest: true }, () => {
      return this.props.checkTokenAction()
        .then(() => {
          return axios({
            method: 'GET',
            url: `${API_DOMAIN}/mde/od/request?request_type=SL&icao=${this.props.icao}${params != null ? params : ''}`,
            headers: { 'x-api-key': `${X_API_KEY}` }
          });
        }).then(result => {
          let data = result.data;
          let dataListOfRequest = [];
          for (let item of data.values) {
            item.request_time = moment.utc(item.request_time).local().format('YYYY-MM-DDTHH:mm:ss')
            if (item.request_status !== 'deleted') {
              dataListOfRequest.push(item);
            }
          }
          this.setState({
            dataListOfRequest: dataListOfRequest.sort(function (a, b) { return moment(b.request_time) - moment(a.request_time) }),
          }, () => {
            this.setState({ loadingListOfRequest: false });
          });
        }).catch((error) => {
          this.setState({ loadingListOfRequest: false });
          message.error(JSON.stringify(error));
        });
    });
  }

  handleSubmit = () => {
    this.formSubmitRequest.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isButtonDisabled: true
        }, () => {
          setTimeout(() => {
            this.setState({
              isButtonDisabled: false
            })
          }, 300000);
          return axios({
            method: 'POST',
            url: `${API_DOMAIN}/mde/sl/request`,
            data: {
              request_user: this.props.username,
              icao: this.props.icao,
              extraction_mode: values.extractionMode
            }
          }).then(result => {
            this.props.toggleLoadingAction(false);
            setTimeout(() => {
              this.getData();
            }, 1000);
            message.success('The request has been submitted', 7);
            this.formSubmitRequest.resetFields();
          }).catch((error) => {
            this.props.toggleLoadingAction(false);
            message.error(JSON.stringify(error), 7);
          });
        })
      }
    });
  };

  handleSubmitFilter = () => {
    this.formSubmitFilter.validateFields((err, values) => {
      let params = '';
      if (values.status !== 'all') {
        params += `&request_status=${values.status}`;
      }
      if (values.rangeTimePicker != null && values.rangeTimePicker[0] != null && values.rangeTimePicker[1] != null) {
        params += `&request_time_from=${values.rangeTimePicker[0].format("YYYY-MM-DD[T]00:00:00")}&request_time_to=${values.rangeTimePicker[1].format("YYYY-MM-DD[T]23:59:59")}`;
      }
      this.getData(params);
    });
  }

  getStatusColor(status) {
    switch (status) {
      case 'initiated':
        return '#FFC600';
      case 'waiting_approval':
        return '#9DDDFF';
      case 'in_progress':
        return '#1697A6';
      case 'rejected':
        return '#F80F00';
      case 'timeout':
        return '#FD4F54';
      case 'completed':
        return '#1697A6';
      case 'approved':
        return '#4EA9F9';
      default:
        return '';
    }
  }

  triggerDownload(requestId) {
    if (requestId === null) {
      return;
    }
    return this.props.toggleLoadingAction(true)
      .then(() => {
        return this.props.checkTokenAction();
      }).then(() => {
        return axios({
          method: 'GET',
          url: `${API_DOMAIN}/mde/od/fileurl?request_type=SL&requestRid=${requestId}`,
          headers: {
            'Authorization': `Bearer ${this.props.id_token}`
          }
        });
      }).then(result => {
        let data = result.data;
        if (data.presigned_url && data.presigned_url != null) {
          window.open(data.presigned_url, '_blank');
        } else {
          message.error('File not found', 7);
        }
        this.props.toggleLoadingAction(false);
      }).catch((error) => {
        this.props.toggleLoadingAction(false);
        if (error.message != null) {
          message.error(error.message, 7);
        } else {
          message.error('File not found', 7);
        }
      });
  }

  canSubmitRequest() {
    for (let item of this.props.permissions) {
      if (item.company != null && item.company.icao != null && this.props.icao != null && this.props.icao === item.company.icao) {
        for (let permission of item.permissions) {
          if (permission.code === 'REQUESTSL') {
            return true;
          }
        }
      }
    }
    return false;
  }

  canViewRequestList() {
    for (let item of this.props.permissions) {
      if (item.company != null && item.company.icao != null && this.props.icao != null && this.props.icao === item.company.icao) {
        for (let permission of item.permissions) {
          if (permission.code === "LINKSSL" || permission.code === "REQUESTSL") {
            return true;
          }
        }
      }
    }
    return false;
  }

  canViewExtractedList() {
    for (let item of this.props.permissions) {
      if (item.company != null && item.company.icao != null && this.props.icao != null && this.props.icao === item.company.icao) {
        for (let permission of item.permissions) {
          if (permission.code === "LINKSSL" || permission.code === "DOWNLOADSL") {
            return true;
          }
        }
      }
    }
    return false;
  }

  canDownloadFile() {
    for (let item of this.props.permissions) {
      if (item.company != null && item.company.icao != null && this.props.icao != null && this.props.icao === item.company.icao) {
        for (let permission of item.permissions) {
          if (permission.code === "LINKSSL" || permission.code === "DOWNLOADSL") {
            return true;
          }
        }
      }
    }
    return false;
  }

  render() {
    let { dataListOfRequest, dataAvailableFiles, loadingListOfRequest, loadingDataAvailableFiles, isButtonDisabled } = this.state;
    const expandedRowRender = (recordParent) => {
      const data = recordParent.items.sort(function (a, b) { return moment(b.request_time) - moment(a.request_time) }) || [];
      return (
        <Table
          size={'small'}
          bordered={true}
          showHeader={false}
          rowKey={record => record.requestRid}
          pagination={data.length > 10}
          dataSource={data}>
          <Column width="55%" title="Request RID" dataIndex="requestRid" key="requestRid" />
          <Column width="25%" align="center" title="Request Time" dataIndex="request_time" key="request_time" />
          <Column width="20%" align="center" title="Action" key="action"
            render={(text, record) => (
              <span>
                {
                  record.request_status === "archived" ?
                    <Tooltip placement="top" title="Your file has been archived! Please contact DX team for further process.">
                      <Button>Archived</Button>
                    </Tooltip> :
                    (this.canDownloadFile() === true && <Button type="primary" onClick={() => this.triggerDownload(record.requestRid)}>Download</Button>)
                }
              </span>
            )} />
        </Table>
      );
    };
    if (this.props.icao === null) {
      return null;
    }
    return (
      <Tabs defaultActiveKey="1" style={{ paddingBottom: '20px' }}>
        {(this.canSubmitRequest() === true || this.canViewRequestList() === true) &&
          <TabPane tab="List of requests" key="1">
            {this.canSubmitRequest() === true &&
              <div style={{ padding: '10px 0', backgroundColor: '#FFFFFF', borderBottom: "1px solid #E5E4E5" }}>
                <SubmitRequestForm
                  ref={(form) => this.formSubmitRequest = form}
                  isButtonDisabled={isButtonDisabled}
                  onSubmit={this.handleSubmit} />
              </div>
            }
            <div style={{ padding: '10px 0', backgroundColor: '#FFFFFF' }}>
              <SubmitFilterForm
                ref={(form) => this.formSubmitFilter = form}
                onSubmit={this.handleSubmitFilter} />
            </div>
            <Table
              scroll={{ x: 1600 }}
              size={'small'}
              bordered={true}
              loading={loadingListOfRequest}
              rowKey={record => record.requestRid}
              dataSource={dataListOfRequest}
              pagination={dataListOfRequest.length > 10}
              title={() => <Button type="primary" ghost onClick={() => this.handleSubmitFilter()}>Refresh</Button>}>
              <Column width={300} title="Request RID" dataIndex="requestRid" key="requestRid" />
              <Column width={150} align="center" title="Status" dataIndex="request_status" key="status"
                render={(text, record) => (
                  <span style={{
                    fontWeight: 'bold',
                    color: text != null ? this.getStatusColor(text.toLowerCase()) : '#fff'
                  }}>
                    {text === 'timedout' ? 'timeout' : text}
                  </span>
                )} />
              <Column width={200} align="center" title="Request Time" dataIndex="request_time" key="request_time" />
              <Column width={200} title="Dataset Name" dataIndex="dataset_name" key="dataset_name" />
              <Column width={450} title="Dataset RID" dataIndex="datasetRid" key="datasetRid" />
              <Column width={350} title="Dataset Path" key="dataset_path"
                render={(text, record) => (
                  <span>
                    <a target="_blank" rel="noopener noreferrer" href={`${DATASET_PATH}/${record.datasetRid}`}>{record.dataset_path}</a>
                  </span>
                )} />
              <Column width={60} align="center" title="ICAO" dataIndex="icao" key="icao" />
              <Column width={130} title="Extraction Mode" dataIndex="extraction_mode" key="extraction_mode" />
              <Column width={450} title="Last TransactionRId" dataIndex="last_transaction_id" key="last_transaction_id" />
            </Table>
          </TabPane>
        }
        {(this.canViewExtractedList() === true || this.canDownloadFile() === true) &&
          <TabPane tab="Available Files" key="2">
            <Table
              size={'small'}
              bordered={true}
              rowKey={record => record.name}
              loading={loadingDataAvailableFiles}
              expandedRowRender={expandedRowRender}
              dataSource={dataAvailableFiles}
              pagination={dataAvailableFiles.length > 10}
              title={() => <Button type="primary" ghost onClick={() => this.getAvailableFileData()}>Refresh</Button>}>
              <Column width="80%" title="Dataset RID" key="datasetRid"
                render={(text, record) => (
                  <span>{record.name}</span>
                )} />
              <Column width="20%" title="Dataset Name" key="dataset_name" dataIndex="dataset_name" />
            </Table>
          </TabPane>
        }
      </Tabs>
    )
  }
}

function mapStateToProps(state) {
  return {
    username: state.global.username,
    permissions: state.global.permissions,
    expires_in: state.global.expires_in,
    refresh_token: state.global.refresh_token,
    id_token: state.global.id_token,
    icao: state.global.icao,
  }
}

export default connect(mapStateToProps, { loginAction, toggleLoadingAction, checkTokenAction })(SecurityLog)
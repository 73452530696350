let domain = 'https://airbus-prod-10241-2j17.auth.eu-west-1.amazoncognito.com';
let client_id = '2g8qv5fehprc71pue184ulti8f';
let client_secret = '1eedjpt462kb7jefpfe2k266b47n34d41l9bo0l1mb51ua1811tp';
let redirect_uri = 'https://d3oky48avvg0h9.cloudfront.net/';
let api_domain = 'https://2v3t3tin79.execute-api.eu-west-1.amazonaws.com/dev';
let sas_url = 'https://2v3t3tin79.execute-api.eu-west-1.amazonaws.com/dev';
let dataset_path = "https://core.skywise.com/workspace/data-integration/dataset/preview";
let s3_private = 'https://d2x7856uy8gyow.cloudfront.net/private';
let sas_env = "DEV";
let x_api_key = "LTlHzARVDJa8e2NxH2EKGaZ5N0rFZY8q9hMExTl9";
let sas_x_api_key = "LTlHzARVDJa8e2NxH2EKGaZ5N0rFZY8q9hMExTl9";
let skywise_oauth = 'https://core.skywise.com/foundry-multipass/api/oauth2';
let oauth_client_id = '3682276a8a04a5427aa4a014c68ba537';
let oauth_client_secret  = 'f93c0b4b30fd61757e78e39dcc1cc6c5';
let oauth_redirect_url =  'https://d3oky48avvg0h9.cloudfront.net/SDE/ExportDS';

switch (process.env.REACT_APP_ENV) {
  case 'dev':
    domain = 'https://airbus-prod-10241-2j17.auth.eu-west-1.amazoncognito.com';
    client_id = 'cul9ou82v17k1f4kjo7lvbni';
    client_secret = 'p78cnnv0av189oo2r5466o0iinubpps3j8261oqff8r0a99etpp';
    redirect_uri = 'https://d2x7856uy8gyow.cloudfront.net/';
    api_domain = 'https://2v3t3tin79.execute-api.eu-west-1.amazonaws.com/dev';
    sas_url = 'https://rqbnyxucih.execute-api.eu-west-1.amazonaws.com/prod';
    dataset_path = "https://core.skywise.com/workspace/data-integration/dataset/preview";
    sas_env = "PROD";
    s3_private = 'https://d2x7856uy8gyow.cloudfront.net/private';
    x_api_key = "LTlHzARVDJa8e2NxH2EKGaZ5N0rFZY8q9hMExTl9";
    sas_x_api_key = "4rj4H6lBOY6eJOrSmRLQu9pKPKUrl1UL638mDIwz";
    oauth_client_id = '3682276a8a04a5427aa4a014c68ba537';
    oauth_client_secret  = 'f93c0b4b30fd61757e78e39dcc1cc6c5';
    oauth_redirect_url =  'https://d2x7856uy8gyow.cloudfront.net/SDE/ExportDS';
    break;
  case 'staging':
    domain = 'https://airbus-prod-10279-2j17.auth.eu-west-1.amazoncognito.com';
    client_id = '5n1lkh33ddq4o6u1mkgeht6bns';
    client_secret = '1u0m06680s6np43o9uhl27u7q0sd9r5u9jgg4g4e5jp7cagbdc1j';
    redirect_uri = 'https://d3oky48avvg0h9.cloudfront.net/';
    api_domain = 'https://j8ueyr3j9d.execute-api.eu-west-1.amazonaws.com/staging';
    sas_url = 'https://rqbnyxucih.execute-api.eu-west-1.amazonaws.com/prod';
    dataset_path = "https://core.skywise.com/workspace/data-integration/dataset/preview";
    sas_env = "PROD";
    s3_private = 'https://d3oky48avvg0h9.cloudfront.net/private';
    x_api_key = "LTlHzARVDJa8e2NxH2EKGaZ5N0rFZY8q9hMExTl9";
    sas_x_api_key = "4rj4H6lBOY6eJOrSmRLQu9pKPKUrl1UL638mDIwz";
    oauth_client_id = '3682276a8a04a5427aa4a014c68ba537';
    oauth_client_secret = 'f93c0b4b30fd61757e78e39dcc1cc6c5';
    oauth_redirect_url = 'https://d3oky48avvg0h9.cloudfront.net/SDE/ExportDS';
    break;
  case 'prod':
    domain = 'https://airbus-prod-10240-2j17.auth.eu-west-1.amazoncognito.com';
    client_id = '5rc157nvh8rklsd0l677vrah1o';
    client_secret = '168mhb2rr2qj51tpfqn3be26a40kokppqkjkai41us3r65hdh34d';
    redirect_uri = 'https://data.services.skywise.com';
    api_domain = 'https://rqbnyxucih.execute-api.eu-west-1.amazonaws.com/prod';
    sas_url = 'https://rqbnyxucih.execute-api.eu-west-1.amazonaws.com/prod';
    dataset_path = "https://core.skywise.com/workspace/data-integration/dataset/preview";
    sas_env = "PROD";
    s3_private = 'https://data.services.skywise.com/private';
    x_api_key = "4rj4H6lBOY6eJOrSmRLQu9pKPKUrl1UL638mDIwz";
    sas_x_api_key = "4rj4H6lBOY6eJOrSmRLQu9pKPKUrl1UL638mDIwz";
    oauth_client_id = '140c428c14fc77216816f6f3adbf8a24';
    oauth_client_secret  = '4fd4a9ca920a160f608985d89285487a';
    oauth_redirect_url =  'https://data.services.skywise.com/SDE/ExportDS';
    break;
  default:
    break;
}

export const DOMAIN = domain;
export const CLIENT_ID = client_id;
export const CLIENT_SECRET = client_secret;
export const REDIRECT_URI = redirect_uri;
export const API_DOMAIN = api_domain;
export const SAS_URL = sas_url;
export const DATASET_PATH = dataset_path;
export const SAS_ENV = sas_env;
export const S3_PRIVATE = s3_private;
export const X_API_KEY = x_api_key;
export const SAS_X_API_KEY = sas_x_api_key;
export const SKYWISE_OAUTH = skywise_oauth;
export const OAUTH_REDIRECT_URI = oauth_redirect_url;
export const OAUTH_CLIENT_ID = oauth_client_id;
export const OAUTH_CLIENT_SECRET = oauth_client_secret;

